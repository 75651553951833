import * as React from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { useMutation } from 'react-query'

import {
  FocusView,
  FocusHeader,
  HeadingGroup,
  Title,
  FocusBody,
  FocusCloseButton,
  FocusClose,
  FocusActions,
  ScrollPanel
} from '@toasttab/buffet-pui-config-templates'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { SubmitButton } from '@toasttab/buffet-pui-forms'

import { createService } from '../app/queries'
import {
  ServiceNameField,
  GithubUsernameField,
  IsSoxRepoField,
  IsPciRepoField
} from './InputFields'
import { ServiceSelector } from './ServiceOptionSelectors'
import { useService } from './ServiceProvider/ServiceProvider'
import { ServiceOptions } from './ServiceOptions'
import { validationSchema } from './FormValidationSchema'
import { ServiceStatus } from './ServiceStatus/ServiceStatus'
import { ServiceDefinition } from '../types/services'

export const CreateServiceForm = () => {
  const { initialValues, setServiceValues } = useService()
  const { showErrorSnackBar } = useSnackBar()

  const mutation = useMutation(
    (values: ServiceDefinition) => createService(values),
    {
      onMutate: (values) => setServiceValues(values)
    }
  )
  React.useEffect(() => {
    if (mutation.isError) {
      showErrorSnackBar('Lotus request failed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isError])

  if (mutation.isSuccess) return <ServiceStatus />

  return (
    <Formik<ServiceDefinition>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutation.mutate(values)
      }}
    >
      <Form id='create-service' autoComplete='false'>
        <CreateServiceFocusView isLoading={mutation.isLoading} />
      </Form>
    </Formik>
  )
}

const CreateServiceFocusView = ({ isLoading }: { isLoading: boolean }) => {
  const formik = useFormikContext()

  React.useEffect(() => {
    formik.setSubmitting(isLoading)
  }, [isLoading])

  return (
    <FocusView>
      <FocusHeader>
        <HeadingGroup>
          <Title>Create new repository</Title>
        </HeadingGroup>
        <FocusActions>
          <SubmitButton
            className='w-full sm:w-auto'
            form='create-service'
            isInProgress={isLoading}
          />
        </FocusActions>
        <FocusClose>
          {/* @ts-ignore */}
          <FocusCloseButton to={-1} />
        </FocusClose>
      </FocusHeader>
      <FocusBody>
        <div className='lg:max-w-3xl pb-2'>
          <ScrollPanel panelId='main'>
            <div className='space-y-4 md:space-y-6'>
              <ServiceSelector />
              <ServiceNameField />
              <IsSoxRepoField />
              <IsPciRepoField />
            </div>
          </ScrollPanel>
          <ScrollPanel panelId='fields' title='Service specific options'>
            <ServiceOptions />
          </ScrollPanel>
          <ScrollPanel panelId='github' title='Github'>
            <GithubUsernameField />
          </ScrollPanel>
        </div>
      </FocusBody>
    </FocusView>
  )
}
