import { ServiceDefinition } from '../types/services'
import { LocalJWTManager } from './LocalJWTManager'

const jwtManager = new LocalJWTManager()

export interface ServiceErrorResponse extends Response {
  message?: string
}
export type ServiceStatusResponse =
  | 'COMPLETE'
  | 'FAILED'
  | 'GITHUB_ERROR'
  | 'ECR_ERROR'
  | 'TEMPLATE_ERROR'

function securedFetch(url: string, init: RequestInit = {}) {
  const jwtHeader = jwtManager.encodedToken
    ? { Authorization: `Bearer ${jwtManager.encodedToken}` }
    : null
  if (jwtHeader) {
    init.headers = { ...(init.headers || {}), ...jwtHeader }
  }
  return fetch(url, init)
}

export const checkResponse = (
  response: ServiceErrorResponse
): Promise<ServiceErrorResponse> => {
  if (response.redirected && !response.ok) {
    //TODO: (mlee) where can this be redirected to?
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response: Response) =>
  checkResponse(response).then((response) => response.json())

export const createService = (values: ServiceDefinition) =>
  securedFetch(`/api/v1/service/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(mapServiceValues(values))
  })

export const serviceStatus = (service: string) =>
  securedFetch(`/api/v1/service/status/${service}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then(toJson)

const mapServiceValues = (values: ServiceDefinition) => {
  const result = {
    ...values,
    user: jwtManager.getUserName()
  }
  const serviceOptions = filterEmptyValues(result.serviceOptions)

  switch (values.serviceType) {
    case 'BFF':
      return {
        ...result,
        serviceOptions: {
          ...serviceOptions,
          // Alias name here because that's how
          // its wired up to the blueprint downstream
          name: result.serviceName,
          blueprintName: 'node/base'
        }
      }
    case 'SPA':
      return {
        ...result,
        serviceOptions: {
          ...serviceOptions,
          // Alias this here because that's how
          // its wired up to the blueprint downstream
          name: result.serviceName
        }
      }
    case 'LIB':
      return {
        ...result,
        serviceOptions: {
          ...serviceOptions
        }
      }
    case 'EMPTY':
      return {
        ...result,
        serviceOptions: {

        }
      }
    default:
      return {
        ...result,
        serviceOptions
      }
  }
}

const filterEmptyValues = (map: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(map).filter(([, value]) => Boolean(value))
  )
}
