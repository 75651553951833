import * as React from 'react'
import { useQuery } from 'react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalBody, ModalFooter } from '@toasttab/buffet-pui-modal'

import {
  ServiceErrorResponse,
  serviceStatus,
  ServiceStatusResponse
} from '../../app/queries'
import { useConfig } from '../../providers/config'
import { useService } from '../ServiceProvider/ServiceProvider'
import { CompletedStatus } from './CompletedStatus/CompletedStatus'
import { ErrorStatus } from './ErrorStatus/ErrorStatus'
import { InProgressStatus } from './InProgressStatus/InProgressStatus'
import { useNavigate } from 'react-router-dom'

const finalStatuses: Array<ServiceStatusResponse> = [
  'COMPLETE',
  'FAILED',
  'GITHUB_ERROR',
  'ECR_ERROR',
  'TEMPLATE_ERROR'
]

const isComplete = (status: ServiceStatusResponse) =>
  finalStatuses.includes(status)

export const ServiceStatusPanel: React.FC = ({ children }) => {
  const navigate = useNavigate()
  return (
    <Modal isOpen={true}>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button onClick={() => navigate(-1)}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export const ServiceStatus = () => {
  const { serviceValues } = useService()
  const { statusPollingInterval } = useConfig()

  /**
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
   *
   * Assigning to new variable names
   * A property can be unpacked from an object and assigned to a variable with a different name than the object property.
   */
  const {
    isLoading,
    error,
    data: status
  } = useQuery<ServiceStatusResponse, ServiceErrorResponse>(
    [serviceValues.serviceName, 'service-status'],
    () => serviceStatus(serviceValues.serviceName),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      refetchInterval: (status) => {
        if (status !== undefined && isComplete(status)) {
          return false
        }
        return statusPollingInterval
      }
    }
  )

  return (
    <ServiceStatusComponent
      isLoading={isLoading}
      error={error}
      status={status}
    />
  )
}

export interface ServiceStatusComponentProps {
  isLoading: boolean
  error?: ServiceErrorResponse | null
  status?: ServiceStatusResponse
}

export const ServiceStatusComponent = ({
  isLoading,
  error,
  status
}: ServiceStatusComponentProps) => {
  if (isLoading) {
    return (
      <ServiceStatusPanel>
        <InProgressStatus status='REQUEST_RECEIVED' />
      </ServiceStatusPanel>
    )
  }

  if (error)
    return (
      <ServiceStatusPanel>
        <ErrorStatus errorMessage={error.message} />
      </ServiceStatusPanel>
    )
  if (!status)
    return (
      <ServiceStatusPanel>
        <ErrorStatus />
      </ServiceStatusPanel>
    )

  if (isComplete(status)) {
    return (
      <ServiceStatusPanel>
        <CompletedStatus status={status} />
      </ServiceStatusPanel>
    )
  }

  //TODO: not sure when this status would be hit
  return (
    <ServiceStatusPanel>
      <InProgressStatus status={status} />
    </ServiceStatusPanel>
  )
}
