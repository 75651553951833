import React from 'react'

import { ServiceDefinition } from '../../types/services'

const DEFAULT_SERVICE: ServiceDefinition = {
  serviceName: '',
  serviceType: '',
  isSoxRepo: false,
  isPciRepo: false,
  githubUsername: '',
  serviceOptions: {
    // An initial value is needed for formik to validate correctly
    className: '',
    packageName: '',
    dbType: '',
    pulsar: '',
    cssScope: '',
    blueprintName: '',
    sentryDsn: '',
    sentryPublicKey: '',
    sentryProjectId: '',
    useAdhocBuilds: true,
    useStorybookDeployments: true
  }
}

type ServiceContextType = {
  serviceValues: ServiceDefinition
  initialValues: ServiceDefinition
  setServiceValues: React.Dispatch<React.SetStateAction<ServiceDefinition>>
}

const ServiceContext = React.createContext<ServiceContextType>({
  serviceValues: DEFAULT_SERVICE,
  initialValues: DEFAULT_SERVICE,
  setServiceValues: () => ''
})

export const ServiceProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [serviceValues, setServiceValues] =
    React.useState<ServiceDefinition>(DEFAULT_SERVICE)

  return (
    <ServiceContext.Provider
      value={{
        initialValues: DEFAULT_SERVICE,
        serviceValues,
        setServiceValues
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export const useService = () => React.useContext(ServiceContext)
