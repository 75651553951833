import React from 'react'
import { LinearProgressBar } from '@toasttab/buffet-pui-progress-indicators'

import {
  getServiceProgressPercent,
  getServiceStatusString,
  StatusKey
} from '../service-status-helpers'
import { useService } from '../../ServiceProvider/ServiceProvider'

export const InProgressStatus = ({ status }: { status: StatusKey }) => {
  const { serviceValues } = useService()
  return (
    <>
      {getServiceStatusString(status, serviceValues)}
      <div className='pt-5'>
        <LinearProgressBar
          progressPercent={getServiceProgressPercent(status, serviceValues)}
          size='md'
          testId='linear-progress-bar'
        />
      </div>
    </>
  )
}
