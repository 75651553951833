import React from 'react'
import cx from 'classnames'
import {
  CheckboxField,
  SelectField,
  TextInputField,
  TextInputFieldProps,
  ToggleSwitchWithText
} from '@toasttab/buffet-pui-forms'
import { useField, useFormikContext } from 'formik'
import { ServiceDefinition } from '../types/services'
import { buildSentryDsn, parseSentryProps } from '../utils/sentry'

const BaseConfigField = (props: TextInputFieldProps) => (
  <TextInputField
    {...props}
    containerClassName={cx('md:w-1/2', props.containerClassName)}
  />
)

export const ServiceNameField = () => {
  const formik = useFormikContext<ServiceDefinition>()

  let label = 'Service name'
  if (formik.values.serviceType === 'LIB') {
    label = 'Library name'
  }

  if (formik.values.serviceType === 'EMPTY') {
    label = 'Repository name'
  }

  return (
    <BaseConfigField
      name='serviceName'
      label={label}
      placeholder={
        formik.values.serviceType === 'SPA' ? 'drones-spa' : 'drones'
      }
    />
  )
}

export const GithubUsernameField = () => (
  <BaseConfigField
    name='githubUsername'
    label='Github username'
    placeholder='sfredette'
  />
)

export const IsSoxRepoField = () => (
  <div>
    <CheckboxField name='isSoxRepo' label='Is SOX in-scope?' />
    <div className='type-caption pt-1 flex items-start text-secondary'>
      If you are unsure, you can check to see if your team is an in-scope
      team&nbsp;
      <a
        className='inline-link'
        href='https://toasttab.atlassian.net/wiki/spaces/RD/pages/2705687529/R+D+In-Scope+SOX+Development+Teams'
        target='_blank'
        rel='noreferrer'
      >
        here
      </a>
      .
    </div>
  </div>
)

export const IsPciRepoField = () => (
  <div>
    <CheckboxField name='isPciRepo' label='Is PCI in-scope?' />
  </div>
)

//#region G2 Fields

export const ClassNameField = () => (
  <BaseConfigField
    name='serviceOptions.className'
    label='Class name'
    placeholder='Drones'
  />
)

export const PackageNameField = () => (
  <BaseConfigField
    name='serviceOptions.packageName'
    label='Package name'
    placeholder='drones'
  />
)

//#endregion

//#region SPA Fields

export const BlueprintName = () => (
  <SelectField
    name='serviceOptions.blueprintName'
    label='Blueprint'
    containerClassName='md:w-1/2'
    options={[
      {
        value: 'spa/child',
        label: 'Child Spa',
        subLabel:
          'Most Toast SPAs are child SPAs. If you are in doubt, this is probably the SPA you want.'
      },
      {
        value: 'spa/layout',
        label: 'Layout Spa',
        subLabel:
          'Use this if you want a SPA to handle routing and load other SPAs (e.g. restaurant-admin-layout).'
      },
      {
        value: 'spa/widget',
        label: 'Widget Spa',
        subLabel:
          'Use this if your SPA appears in just a part of multiple pages (e.g. spa-dev-tools).'
      }
      // TODO: Enable when we have a blueprint for root-config SPAs (WEX-2025)
      // {
      //   value: 'spa/root',
      //   label: 'Root Spa',
      //   subLabel:
      //     'This is for a root level loader of SPAs (e.g. wex-banquet-root).'
      // }
    ]}
  />
)

export const AdhocBuild = () => (
  <ToggleSwitchWithText
    name='serviceOptions.useAdhocBuilds'
    title='Enable Adhoc Builds'
    details='If enabled, this will allow you to get deployable builds of your SPA before merging into the main branch.'
    containerClassName='md:ml-16'
  />
)

export const StorybookDeploy = () => (
  <ToggleSwitchWithText
    name='serviceOptions.useStorybookDeployments'
    title='Enable Storybook Deployments'
    details='If enabled, this will deploy your Storybook to https://doc.toasttab.com/internal/storybooks/{spa-name}'
    containerClassName='md:ml-16'
  />
)

export const CSSScope = () => (
  <BaseConfigField
    name='serviceOptions.cssScope'
    label='Tailwind CSS Scope'
    placeholder='data-app'
  />
)

export const SentryDSN = () => {
  const formik = useFormikContext<ServiceDefinition>()
  const serviceName = formik.values.serviceName
  const [, , publicKeyHelper] = useField('serviceOptions.sentryPublicKey')
  const [, , projectIdHelper] = useField('serviceOptions.sentryProjectId')
  const [, , dsnHelper] = useField('serviceOptions.sentryDsn')
  return (
    <BaseConfigField
      name='serviceOptions.sentryDsn'
      label='Sentry DSN'
      placeholder='https://{publicKey}.ingest.sentry.io/{projectId}'
      onChange={(e) => {
        dsnHelper.setValue(e.currentTarget.value)
        const sentryProps = parseSentryProps(e.currentTarget.value)
        if (sentryProps) {
          publicKeyHelper.setValue(sentryProps.publicKey)
          projectIdHelper.setValue(sentryProps.projectId)
        }
      }}
      helperText={
        <p>
          You can get the DSN from{' '}
          <a
            href={`https://sentry.io/settings/toast/projects/${serviceName}/keys/`}
            target='blank'
            rel='nofollow'
            className='inline-link'
          >
            https://sentry.io/settings/toast/projects/
            <em>{serviceName}</em>/keys/
          </a>
        </p>
      }
    />
  )
}

export const SentryPublicKey = () => {
  const formik = useFormikContext<ServiceDefinition>()

  const [, , publicKeyHelper] = useField('serviceOptions.sentryPublicKey')
  const [, , dsnHelper] = useField('serviceOptions.sentryDsn')

  return (
    <BaseConfigField
      containerClassName='md:ml-4'
      name='serviceOptions.sentryPublicKey'
      label='Sentry Public Key'
      onChange={(e) => {
        const publicKey = e.currentTarget.value
        const projectId = formik.values.serviceOptions.sentryProjectId
        publicKeyHelper.setValue(publicKey)
        if (publicKey && projectId) {
          dsnHelper.setValue(
            buildSentryDsn({
              publicKey,
              projectId
            })
          )
        }
      }}
    />
  )
}

export const SentryProjectId = () => {
  const formik = useFormikContext<ServiceDefinition>()
  const [, , projectIdHelper] = useField('serviceOptions.sentryProjectId')
  const [, , dsnHelper] = useField('serviceOptions.sentryDsn')

  return (
    <BaseConfigField
      containerClassName='md:ml-4'
      name='serviceOptions.sentryProjectId'
      label='Sentry Project Id'
      onChange={(e) => {
        const publicKey = formik.values.serviceOptions.sentryPublicKey
        const projectId = e.currentTarget.value
        projectIdHelper.setValue(projectId)
        if (publicKey && projectId) {
          dsnHelper.setValue(
            buildSentryDsn({
              publicKey,
              projectId
            })
          )
        }
      }}
    />
  )
}

export const SlackChannel = () => (
  <BaseConfigField
    name='serviceOptions.slackChannel'
    label='Slack channel'
    placeholder='#wex-dev-alerts'
  />
)

export const CodeOwner = () => (
  <BaseConfigField
    name='serviceOptions.codeOwner'
    label='Code Owners'
    placeholder='@toasttab/wex'
  />
)

export const MountPointId = () => (
  <BaseConfigField
    name='serviceOptions.mountPointId'
    label='Mount Point'
    placeholder='some_element_id'
    helperText='The id of the element this SPA will be rendered into (do not include the #).'
  />
)

export const LayoutSPAChildDetails = () => (
  <>
    <div>
      <h4 className='mb-1 inline-block type-default font-semibold text-default'>
        Layout SPA children
      </h4>
      <p className='text-secondary type-subhead'>
        Please give the details of a child SPA that this layout will render. We
        will use these details to set up an example SPA loader for you.
      </p>
    </div>
    <BaseConfigField
      name='serviceOptions.childSpaName'
      label='Child SPA Name'
      placeholder='dashboard-spa'
      containerClassName='md:ml-4'
    />
    <BaseConfigField
      name='serviceOptions.childSpaRoute'
      label='Child SPA Route'
      placeholder='path/to/spa'
      containerClassName='md:ml-4'
    />
  </>
)
//#endregion
