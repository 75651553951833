import React from 'react'
import { RadioGroupField } from '@toasttab/buffet-pui-forms'
import { useFormikContext } from 'formik'
import { ServiceDefinition } from '../types/services'

export const ServiceSelector = () => {
  return (
    <div>
      <RadioGroupField
        label='Service type'
        name='serviceType'
        testId='service-type'
        options={[
          { value: 'G2', label: 'REST (G2) Service' },
          { value: 'SPA', label: 'Single Page App (SPA)' },
          { value: 'BFF', label: 'Backend For Frontend (BFF)' },
          { value: 'GRPC', label: 'gRPC' },
          { value: 'CAMEL', label: 'Camel' },
          { value: 'LIB', label: 'Shared Library' },
          { value: 'SCHEDULED_TASK', label: 'Scheduled Task (new repo)' },
          { value: 'EMPTY', label: 'Empty Repository' }
        ]}
        helperText={
          <>
            For other service types, please refer to&nbsp;
            <a
              className='inline-link'
              href='https://docs.tdp.eng.toasttab.com/html/docsrc/how-to-create-a-service.html#how-to-create-a-service-in-tdp'
              target='_blank'
              rel='noreferrer'
            >
              this doc
            </a>
            .
          </>
        }
      />
    </div>
  )
}

export const DBSelector = () => {
  const formik = useFormikContext<ServiceDefinition>()
  let serviceSpecificOptions
  switch (formik.values.serviceType) {
    case 'CAMEL':
      serviceSpecificOptions = [
        { value: 'NONE', label: 'None' },
        { value: 'DYNAMODB', label: 'NoSQL (Dynamo)' }
      ]
      break
    default:
      serviceSpecificOptions = [
        { value: 'NONE', label: 'None' },
        { value: 'DYNAMODB', label: 'NoSQL (Dynamo)' },
        { value: 'POSTGRESQL', label: 'PostgreSQL' }
      ]
  }

  return (
    <RadioGroupField
      label='Database type'
      name='serviceOptions.dbType'
      testId='database-type'
      options={serviceSpecificOptions}
    />
  )
}

export const PulsarOption = () => {
  const formik = useFormikContext<ServiceDefinition>()
  let serviceSpecificOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'ENABLED', label: 'Enable Pulsar' }
  ]
  return (
    <RadioGroupField
      label='Pulsar'
      name='serviceOptions.pulsar'
      testId='pulsar-option'
      options={serviceSpecificOptions}
    />
  )

}
