import * as React from 'react'
import { useFormikContext } from 'formik'
import { DBSelector, PulsarOption } from './ServiceOptionSelectors'
import { ServiceDefinition } from '../types/services'
import {
  BlueprintName,
  ClassNameField,
  CodeOwner,
  CSSScope,
  PackageNameField,
  SentryDSN,
  SentryProjectId,
  SentryPublicKey,
  SlackChannel,
  MountPointId,
  LayoutSPAChildDetails,
  AdhocBuild,
  StorybookDeploy
} from './InputFields'

const FieldWrapper: React.FC = ({ children }) => (
  <div className='space-y-4 md:space-y-6'>{children}</div>
)

const ServiceOptionsForSPAs = () => {
  const { values, setFieldValue } = useFormikContext<ServiceDefinition>()
  const isWidgetSpa = values.serviceOptions.blueprintName === 'spa/widget'
  const isLayoutSpa = values.serviceOptions.blueprintName === 'spa/layout'

  // Remove values if user changes their mind
  // (not sure if this is really necessary...)
  React.useEffect(() => {
    if (!isWidgetSpa) {
      setFieldValue('serviceOptions.mountPointId', '')
    }
    if (!isLayoutSpa) {
      setFieldValue('serviceOptions.childSpaName', '')
      setFieldValue('serviceOptions.childSpaRoute', '')
    }
  }, [isWidgetSpa, isLayoutSpa, setFieldValue])

  return (
    <FieldWrapper>
      <BlueprintName />
      <CSSScope />
      {isWidgetSpa && <MountPointId />}
      {isLayoutSpa && <LayoutSPAChildDetails />}
      <SentryDSN />
      <SentryPublicKey />
      <SentryProjectId />
      <SlackChannel />
      <CodeOwner />
      <AdhocBuild />
      <StorybookDeploy />
    </FieldWrapper>
  )
}

const ServiceOptionsForBFFs = () => {
  return (
    <FieldWrapper>
      <SlackChannel />
      <CodeOwner />
    </FieldWrapper>
  )
}

const ServiceOptionsForSharedLibraries = () => {
  return (
    <FieldWrapper>
      <PackageNameField />
      <CodeOwner />
    </FieldWrapper>
  )
}

const ServiceOptionsForScheduledTask = () => {
  return (
    <FieldWrapper>
      <ClassNameField />
      <PackageNameField />
      <PulsarOption />
    </FieldWrapper>
  )
}

export const ServiceOptions = () => {
  const formik = useFormikContext<ServiceDefinition>()
  switch (formik.values.serviceType) {
    case 'G2':
    case 'CAMEL':
      return (
        <FieldWrapper>
          <ClassNameField />
          <PackageNameField />
          <DBSelector />
        </FieldWrapper>
      )
    case 'GRPC':
      return (
        <FieldWrapper>
          <ClassNameField />
          <PackageNameField />
        </FieldWrapper>
      )
    case 'SPA':
      return <ServiceOptionsForSPAs />
    case 'BFF':
      return <ServiceOptionsForBFFs />
    case 'SCHEDULED_TASK':
      return <ServiceOptionsForScheduledTask />
    case 'LIB':
      return <ServiceOptionsForSharedLibraries />
    case 'EMPTY':
      return <></>
    default:
      return (
        <div className='type-default text-secondary'>
          Choose a service type above
        </div>
      )
  }
}
