import { ServiceDefinition } from '../../types/services'

type StatusMapValue = { text: string; percent: number }
type StatusMap = {
  REQUEST_RECEIVED: StatusMapValue
  CREATING_GITHUB_REPO: StatusMapValue
  CREATING_ECR_REPO: StatusMapValue
  POPULATING_TEMPLATE: StatusMapValue
  COMPLETE: StatusMapValue
  FAILED: StatusMapValue
  GITHUB_ERROR: StatusMapValue
  ECR_ERROR: StatusMapValue
  TEMPLATE_ERROR: StatusMapValue
}
export type StatusKey = keyof StatusMap

const serviceStatusMap = (serviceValues: ServiceDefinition): StatusMap => ({
  REQUEST_RECEIVED: {
    text: 'Joining Metaverse...',
    percent: 0
  },
  CREATING_GITHUB_REPO: {
    text: `Creating ${serviceValues.serviceName} github repo...`,
    percent: 10
  },
  CREATING_ECR_REPO: {
    text: `Configuring AWS ECR repo toast/${serviceValues.serviceName}...`,
    percent: 45
  },
  POPULATING_TEMPLATE: {
    text: `Populating repo from ${serviceValues.serviceType} template...`,
    percent: 77
  },
  COMPLETE: {
    text: 'Done! Check out this link for your repo!',
    percent: 100
  },
  FAILED: {
    text: `Uh oh! Failed to create ${serviceValues.serviceName}`,
    percent: 0
  },
  GITHUB_ERROR: {
    text: "Uh oh! We've encountered and error with github",
    percent: 0
  },
  ECR_ERROR: {
    text: "Uh oh! We've encountered an error with Amazon ECR",
    percent: 0
  },
  TEMPLATE_ERROR: {
    text: "Uh oh! We've encountered an error with the service template",
    percent: 0
  }
})

export const getServiceStatusString = (
  key: StatusKey,
  serviceValues: ServiceDefinition
) => serviceStatusMap(serviceValues)[key]?.text || 'Unknown'

export const getServiceProgressPercent = (
  key: StatusKey,
  serviceValues: ServiceDefinition
) => serviceStatusMap(serviceValues)[key]?.percent || -1
