import React from 'react'

export const ErrorStatus = ({ errorMessage }: { errorMessage?: string }) => {
  return (
    <>
      An unexpected error has occurred
      {errorMessage ?? `: ${errorMessage}`}
    </>
  )
}
