export interface SentryProps {
  publicKey: string
  projectId: string
}

export const parseSentryProps = (dsn: string): SentryProps | null => {
  const match = dsn.match('^https://(.*).ingest.sentry.io/(.*)')
  if (match && match.length === 3) {
    return {
      publicKey: match[1],
      projectId: match[2]
    }
  }
  return null
}

export const buildSentryDsn = (props: SentryProps): string =>
  `https://${props.publicKey}.ingest.sentry.io/${props.projectId}`
