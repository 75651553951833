import * as React from 'react'

const ConfigContext = React.createContext({
  statusPollingInterval: 10000,
  container: () =>
    document.getElementsByClassName('single-spa-application:dx-lilypad')[0]
})

export const useConfig = () => React.useContext(ConfigContext)
export const ConfigProvider = ConfigContext.Provider
