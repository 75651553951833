import * as yup from 'yup'

// DO FORM VALIDATION BASED ON THE SERVICE TYPE

export const validationSchema = yup.object().shape({
  serviceName: yup.string().when('serviceType', (serviceType, schema) => {
    return serviceType === 'LIB'
      ? schema
          .matches(/^library-(?:[a-z]+-?)+[a-z]+$/)
          .required('This field is required')
      : schema
          .matches(/^(?:[a-z]+-?)+[a-z]$/)
          .required('This field is required')
  }),
  githubUsername: yup
    .string()
    .matches(
      /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i,
      'Username may not contain spaces or special characters or be greater than 39 characters'
    )
    .required('This field is required'),
  serviceType: yup.string().required('This field is required'),
  serviceOptions: yup.object().when('serviceType', (serviceType, schema) => {
    switch (serviceType) {
      case 'G2':
      case 'CAMEL':
        return schema.shape({
          className: yup
            .string()
            .matches(/^[A-Z][a-zA-Z0-9]+$/, 'Class name must begin with a capital letter and not contain trailing whitespace')
            .required('This field is required'),
          packageName: yup
            .string()
            .matches(
              /^[a-z]+$/,
              'Package name must be all lowercase with no hypens'
            )
            .required('This field is required'),
          dbType: yup.string().required('This field is required')
        })
      case 'GRPC':
        return schema.shape({
          className: yup
            .string()
            .matches(/^[A-Z][a-zA-Z0-9]+$/, 'Class name must begin with a capital letter and not contain trailing whitespace')
            .required('This field is required'),
          packageName: yup
            .string()
            .matches(
              /^[a-z]+$/,
              'Package name must be all lowercase with no hypens'
            )
            .required('This field is required')
        })
      case 'SCHEDULED_TASK':
        return schema.shape({
          className: yup
            .string()
            .matches(/^[A-Z][a-zA-Z0-9]+$/, 'Class name must begin with a capital letter and not contain trailing whitespace')
            .required('This field is required'),
          packageName: yup
            .string()
            .matches(
              /^[a-z]+$/,
              'Package name must be all lowercase with no hypens'
            )
            .required('This field is required'),
          pulsar: yup.string().required('This field is required')
        })
      case 'SPA':
        return schema.shape({
          blueprintName: yup.string().required('This field is required'),
          cssScope: yup.string().required('This field is required'),
          sentryPublicKey: yup
            .string()
            .matches(
              /[a-zA-Z0-9]+@[a-zA-Z0-9]+/,
              'Must be of the pattern <a-zA-Z0-9>@<a-zA-Z0-9>'
            ),
          slackChannel: yup
            .string()
            .matches(
              /^#[a-z0-9-_]+$/,
              "Must start with '#' and contain only lowercase letters, '-', and '_'"
            )
            .max(81, 'Must be at most 80 characters')
        })
      case 'BFF':
        return schema.shape({
          slackChannel: yup
            .string()
            .matches(
              /^#[a-z0-9-_]+$/,
              "Must start with '#' and contain only lowercase letters, '-', and '_'"
            )
            .max(81, 'Must be at most 80 characters')
        })
      default:
        return schema.shape({})
    }
  })
})
