import * as React from 'react'

import { IconLinkButton } from '@toasttab/buffet-pui-buttons'
import { LinkIcon } from '@toasttab/buffet-pui-icons'

import { getServiceStatusString, StatusKey } from '../service-status-helpers'
import { useService } from '../../ServiceProvider/ServiceProvider'

export const CompletedStatus = ({ status }: { status: StatusKey }) => {
  const { serviceValues } = useService()
  return (
    <div className='flex items-center space-x-4'>
      <>{getServiceStatusString(status, serviceValues)}</>
      {status === 'COMPLETE' && (
        <IconLinkButton
          href={`https://github.toasttab.com/toasttab/${serviceValues.serviceName}`}
          icon={<LinkIcon />}
        />
      )}
    </div>
  )
}
