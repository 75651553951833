import React from 'react'
import { BanquetCustomProps } from '@toasttab/banquet-types'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { LayoutProvider } from '@toasttab/buffet-pui-config-templates'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

import { CreateServiceForm } from '../components/CreateServiceForm'
import { ServiceProvider } from '../components/ServiceProvider/ServiceProvider'

const client = new QueryClient()

interface AppProps extends BanquetCustomProps {
  basename?: string
}

export const App = ({ basename = '' }: AppProps) => {
  return (
    <BrowserRouter basename={basename}>
      <LayoutProvider>
        <ServiceProvider>
          <SnackBarProvider>
            <QueryClientProvider client={client}>
              <Routes>
                <Route path='create' element={<CreateServiceForm />} />
              </Routes>
            </QueryClientProvider>
          </SnackBarProvider>
        </ServiceProvider>
      </LayoutProvider>
    </BrowserRouter>
  )
}
